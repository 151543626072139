import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated } from "@react-spring/web";

const CountUpOnScroll = ({ start = 0, end = 100, duration = 2000, prefix = '', suffix = ''}) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  // Intersection Observer to detect visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 }
    );

    if (ref.current) observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  // Animate numbers using react-spring
  const { number } = useSpring({
    number: isVisible ? end : start,
    from: { number: start },
    config: { duration: duration },
  });

  return (
    <div ref={ref}>
      {prefix}
      <animated.span>
        {number.to((n) => Math.floor(n).toLocaleString())}
      </animated.span>
      {suffix}
    </div>
  );
};

export default CountUpOnScroll;